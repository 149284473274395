import React from 'react'
import { Grid, makeStyles, Card } from '@material-ui/core'
import { contentMaxWidth, generalTextColor, googlePartner, facebookPartner } from '../utitlities/Configurations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ButtonList from './General/Buttons/ButtonList'
import clsx from 'clsx'
import Form from './Contact/Form'
import { useStaticQuery, graphql } from 'gatsby'
import TitleComponent from './General/TitleComponent'
import Carousel from './General/Carousel'
import VideoComponent from './General/VideoComponent'
import { socialIconList } from '../utitlities/IconRenderMap'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        paddingTop: "76px",
        display: "flex",
        alignItems: 'center',
        // maxWidth: containerMaxWidth,
        margin: 'auto',
        marginBottom: "2rem",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "3rem",
        }
    },
    dots: {
        bottom: '0px'
    },
    content: {
        width: '100%',
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "1rem 1rem 2rem",
        [theme.breakpoints.up('sm')]: {
            padding: "4rem 2rem",
        }
    },
    containerBg: {
        backgroundColor: theme.palette.primary.light
    },
    carouselCard: {
        margin: '10px',
        width: "calc(100% - 20px) !important",
        // height: '300px',
        // [theme.breakpoints.up('sm')]: {
        //     height: '340px',
        // }
    },
    shape1: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: "100%",
        // width: '100%'
    },
    shape2: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        top: 0,
        zIndex: -1,
        height: "100%"
    },
    image: {
        height: '110%'
    },
    subHeading: {
        color: generalTextColor
    },
    formCard: {
        borderRadius: "6px",
        backgroundColor: 'white',
        padding: '1rem',
        [theme.breakpoints.up("sm")]: {
            padding: "3rem 2rem"
        }
    },
    gridContainer: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between'
        }
    }
}))


export default function Banner({
    heading = '', subHeading = "", secondaryHeadings = [], bannerId = '', containerStyles = {}, showImage = true, cardStyles = {}, showCarousel = false,
    imageComponent, buttonList = [], showFormInsteadButton = false, imageUrl = false, formId = '', formDivId = '', showCard = false, imageList = [], videoList = [],
    slidesInMd = 3, slidesInSm = 2, slidesInXs = 1, showIcon = false
}) {
    const classes = useStyles()
    const [imgPos, setImgPos] = React.useState({ x: 0, y: 0 })
    const bannerQuery = useStaticQuery(graphql`
    query BannerQuery {
        bannerImage: allFile(
            filter: {sourceInstanceName: {eq: "images"}, name: {eq: "Banner"}}
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
      }          
    `)
    React.useEffect(() => {
        let mouseMove = (e) => {
            let clientX = e.clientX
            let clientY = e.clientY
            let widthCenter = window.innerWidth / 2
            let heightCenter = window.innerHeight / 2
            let xDisplace = (widthCenter - clientX) / 55
            let yDisplace = (heightCenter - clientY) / 60
            setImgPos({ x: xDisplace, y: yDisplace })
        }
        if (!showCarousel && !showCard && showImage) {
            window.addEventListener("mousemove", mouseMove)
        }
        return () => {
            if (!showCarousel && !showCard && showImage) {
                window.removeEventListener('mousemove', mouseMove)
            }
        }
    }, [showCarousel, showCard, showImage])
    const bannerImageData = bannerQuery.bannerImage.edges[0].node
    return <div className={clsx(classes.container)} id={bannerId} style={containerStyles} >
        <div className={classes.shape1}>
            <GatsbyImage image={getImage(bannerImageData)} alt="Header Banner" className={classes.image} imgStyle={{
                objectFit: 'fill'
            }}
            />
        </div>
        {/* {
            !showBg &&
            <div className={classes.shape1}>
                <StaticImage src="../images/Shape1.png" alt='Shape1' className={classes.image} />
            </div>
        }
        {
            !showBg &&
            <Hidden xsDown implementation='css'>
                <div className={classes.shape2}>
                    <StaticImage src="../images/Shape2.png" alt='Shape2' className={classes.image} />
                </div>
            </Hidden>
        } */}
        <div className={classes.content}>
            <Grid container spacing={4} className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={showCarousel ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TitleComponent
                                title={heading} titleVariant='h1'
                                secondaryTitles={secondaryHeadings}
                                titleSpacing={4}
                                titleAlign={showCarousel ? 'center' : 'flex-start'} titleComponent='h1'
                                isFullWidth={true}
                                subHeading={subHeading} subHeadingVariant='body1'
                            />
                        </Grid>
                        {
                            (showFormInsteadButton) ?
                                <Grid item xs={12}>
                                    <Form formId={formId} formDivId={formDivId} showFullWidth={true} />
                                </Grid>
                                :
                                <>
                                    {
                                        buttonList.length !== 0 &&
                                        <Grid item xs={12}>
                                            <ButtonList buttonList={buttonList} />
                                        </Grid>
                                    }
                                </>
                        }
                        {
                            showIcon &&
                            <Grid item container alignItems="center" xs={12} spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    {socialIconList[facebookPartner]}
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {socialIconList[googlePartner]}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={showCarousel ? 12 : 9} md={showCarousel ? 12 : showImage ? 6 : 5} style={showImage ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
                    {
                        (showCarousel) ?
                            <div style={{ width: '100%' }}>
                                <Carousel showDots={true} dataList={imageList.concat(videoList)}
                                    slidesInMd={videoList.length > 0 ? 2 : 3}
                                    slidesInSm={videoList.length > 0 ? 1 : 2}
                                    slidesInXs={1}
                                    otherSettings={{
                                        autoplay: videoList.length <= 0
                                    }}
                                >
                                    {
                                        imageList.map(item => {
                                            return <Card elevation={4} className={classes.carouselCard}>
                                                <GatsbyImage image={getImage(item)} alt={item.name} style={{
                                                    width: '100%',

                                                }} imgStyle={{
                                                    objectFit: 'cover', width: '100%'
                                                }}
                                                />
                                            </Card>
                                        })
                                    }
                                    {
                                        videoList.map(item => {
                                            return <Card elevation={4} className={classes.carouselCard}>
                                                <VideoComponent
                                                    url={item}
                                                />
                                            </Card>
                                        })
                                    }
                                </Carousel>
                            </div>
                            :
                            <>
                                {
                                    showCard ?
                                        <Card elevation={4} className={classes.formCard} style={cardStyles}>
                                           {
                                                (showImage && !imageUrl) ?
                                                    <GatsbyImage image={getImage(imageComponent)} alt={bannerId} style={{
                                                        width: '100%'
                                                    }} imgStyle={{
                                                        objectFit: 'contain',
                                                        width: '100%'
                                                    }}
                                                    /> :
													(showImage && !!imageUrl) ?
													<img src={imageComponent.url} layout='constrained'  alt={bannerId} style={{
                                                        width: '100%',
                                                        objectFit: 'contain',
                                                    }} 
                                                    />
													:
                                                    <Form formId={formId} formDivId={formDivId} showFullWidth={true} />
                                            }

                                        </Card> :
                                        <>
                                           {
                                                (showImage && !imageUrl) ?
                                                    <GatsbyImage image={getImage(imageComponent)} alt={bannerId} style={{
                                                        width: '100%'
                                                    }} imgStyle={{
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        transform: `translate3d(${imgPos.x}px,${imgPos.y}px,0px)`
                                                    }}
                                                    />
                                                    :
													(showImage && !!imageUrl) ?
                                                    (imageComponent.url === '') ? <p></p>
                                                    :
                                                    <img src={imageComponent.url}  layout='constrained'  alt={bannerId} style={{
                                                                                                            objectFit: 'contain',
                                                                                                            width: '100%',
                                                                                                            transform: `translate3d(${imgPos.x}px,${imgPos.y}px,0px)`
                                                                                                        }}
                                                                                                        />
													:
                                                    <Form formId={formId} formDivId={formDivId} showFullWidth={true} />
                                            }
                                        </>
                                }
                            </>
                    }

                </Grid>
            </Grid>
        </div>
    </div >
}