import React from 'react'
import { makeStyles } from '@material-ui/core'
import { contentMaxWidth } from '../../utitlities/Configurations'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "0.5rem 1rem 2rem",
        [theme.breakpoints.up("sm")]: {
            padding: "1rem 2rem 2rem"
        },
        [theme.breakpoints.up("md")]: {
            padding: "1rem 2rem 2rem"
        }
    }
}))

export default function SectionWrapper({ className, styles = {}, sectionClassName, sectionStyles = {}, children, sectionId = '' }) {
    const classes = useStyles()
    return <div id={sectionId} className={sectionClassName} style={sectionStyles}>
        <div style={styles} className={clsx(classes.container, className)}>
            {children}
        </div>
    </div>
}