import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        borderRadius: '4px',
        '&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.secondary.main
        }
    }
}))

export default function PrimaryButton({ label, buttonProps = {} }) {
    const classes = useStyles()
    return <Button
        variant='contained'
        disableElevation
        fullWidth={false}
        className={classes.button}
        {...buttonProps}
    >
        {label}
    </Button>
}