import React from 'react'
import PrimaryButton from './Primary'
import { Grid } from '@material-ui/core'

export default function ButtonList({ buttonList = [], buttonSpacing = 2, buttonsAlign = 'flex-start' }) {
    return <Grid container spacing={buttonSpacing} justifyContent={buttonsAlign} alignItems='center'>
        {
            buttonList.map(button => {
                const buttonId = button.buttonProps?.id
                // const buttonType = button.type
                return <Grid item key={buttonId}>
                    <PrimaryButton {...button} />
                </Grid>
            })
        }
    </Grid>
}