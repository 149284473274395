import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import TabComponent from '../components/General/TabComponent'
// import PricingPack from '../components/Services/PricingPack'
import { graphql } from 'gatsby'
import ContactForm from '../components/Contact/Form'
import { getStartedFormId, getStartedFormDivId, mainLeadFormDivId, mainLeadFormId } from '../utitlities/Configurations'
import PricingSections from "../components/Services/PricingSections"
import { fetchData } from '../utitlities/helperFunctions'

const PricingPage = ({ data }) => {
  
  const [PricingData,SetData] = useState()
  const [ServiceData,SetAll] = useState()
  
  const pricingPageData = PricingData ? PricingData : data.pricing.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}
  const serviceList = ServiceData ? ServiceData : data.services.edges[0].node
  
  const [tab, setTab] = useState(serviceList.links[0].id)
  const [pricingSelectedService, setPricingSelectedService] = useState(serviceList.links[0])   

  useEffect(() => { 
    fetchData(`pricing`)
    .then(x => SetData(x) )
    fetchData(`services`)
    .then(data => {
      SetAll(data)
      setPricingSelectedService(data.links[0])
    });
  }, []); 


  return (
    <Layout pageSeo={pricingPageData.Seo}>
      <Banner
        bannerId="pricing-banner"
        containerStyles={{ marginBottom: '0px' }}
        heading={pricingPageData.heading}
        subHeading={pricingPageData.subHeading}
        secondaryHeadings={pricingPageData.headingHighlights}
        showFormInsteadButton={true}
        formId={getStartedFormId}
        imageUrl={true}
        formDivId={getStartedFormDivId}
        imageComponent={bannerImageData} />


      <TabComponent
        list={serviceList.links.filter(item => item.pricingSections.length > 0).map(item => ({ value: item.id, label: item.shortTitle }))}
        value={tab}
        label={pricingSelectedService.shortTitle}
        onChange={(value) => {
          setTab(value)
          setPricingSelectedService(serviceList.links.find(item => item.id === value))
        }}
      />


      <div style={{ paddingTop: '1rem' }}>
        
        <PricingSections
          key={pricingSelectedService.id}
          sections={pricingSelectedService.pricingSections.map(item => ({
            title: item.pricingSectionHeading,
            subHeading: item.pricingSectionSubHeading,
            smallTitle: '',
            secondaryTitles: [],
            packs: item.pricingPacks
          }))}
        />
        

        <ContactForm title="Get Free Quote Now" secondaryTitles={['Free', 'Quote']} showTitle={true} titleComponent="h2" titleVariant="h2"
          formId={mainLeadFormId}
          smallTitle="Contact Us"
          formDivId={`${mainLeadFormDivId}-footer`}
        // description={"We would love to hear from you about your upcoming projects, Please mention the type of your event, event date, location, timing and how many people attending the event, etc."}
        />
      </div>

    </Layout>
  )
}

export const query = graphql`
query PricingPageQuery {
    pricing: allApiPricing {
      edges {
        node {
  
        Seo {
                description
                title
              }
              heading
              headingHighlights
              subHeading
        }
      }
    }
    bannerImage: allFile(
        filter: {sourceInstanceName: {eq: "images"}, name: {eq: "PricingBanner"}}
      ) {
        edges {
          node {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      services: allApiServices {
        edges {
          node {
            links {
              title
              shortTitle
              id
              order
              pricingSections{
                id
                pricingPacks{                         
                  packName
                  packDescription
                  packValue
                  features{
                    id
                    feature
                    opted                           
                  }
              }
              pricingSectionHeading
              pricingSectionSubHeading
              }
            }
          }
        }
      }
  }
  
`

export default PricingPage
