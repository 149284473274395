import React from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import PrimaryButton from '../General/Buttons/Primary'
import TickIcon from '@material-ui/icons/Check'
import UnTickIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#f9f9f9',
        height: '100%',
        borderRadius: '6px',
        padding: '1rem',
        transition: "all 0.2s ease-in-out",
        [theme.breakpoints.up('sm')]: {
            padding: "3rem 2rem"
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            boxShadow: '0px 16px 27px 0px rgb(0 0 0 / 8%)',
        }
    }
}))

export default function PricingCard({ data, ctaProps = { label: "Talk to Sales" } }) {
    const classes = useStyles()
    const { packName, packDescription, packValue, features } = data
    return <div className={classes.container}>
        <Grid container direction='column' style={{ height: '100%' }} alignItems='center' justifyContent='space-between' >
            <Grid item style={{ flex: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h4' component='p' align='center' style={{ textTransform: "capitalize" }}>{packName}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item>
                                        <Typography variant='body2'>Starts at</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' style={{ fontWeight: 800 }} component='p' color='secondary'>{packValue} AED</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !!packDescription &&
                                <Grid item xs={12}>
                                    <Typography align='center' variant='body2' style={{ fontWeight: "600" }}>{packDescription}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        features && features.length !== 0 &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {
                                    features.map(feature => {
                                        return <Grid item xs={12} key={feature.id}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                                                        <Grid item style={{ display: 'flex' }}>
                                                            {
                                                                feature.opted ?
                                                                    <TickIcon color='primary' style={{

                                                                        fontWeight: 600
                                                                    }} />
                                                                    :
                                                                    <UnTickIcon color='error' style={{


                                                                        fontWeight: 600
                                                                    }} />
                                                            }
                                                        </Grid>
                                                        <Grid item >
                                                            <Typography>{feature.feature}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* {
                                                    (!!feature.subFeatures && feature.subFeatures.length !== 0) &&
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                feature.subFeatures.map(subFeature => {
                                                                    return <Grid item xs={12} key={subFeature.id}>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item>
                                                                                <div style={{ width: "2.2rem" }}></div>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant='body2' style={{ fontWeight: "600" }}>{subFeature.subFeature}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                } */}
                                            </Grid>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item>
                <div style={{ paddingTop: '2rem' }}>
                    <PrimaryButton
                        {...ctaProps}
                    />
                </div>
            </Grid>
        </Grid>
    </div>
}