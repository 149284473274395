import React, {useEffect} from 'react'
import PricingPack from './PricingPack'


export default function PricingSections({ sections = [], sectionId = 'pricing' }) {
    return <div id={sectionId}>
        {
            sections.map((item, index) => {
                // console.log(item)
                return <PricingPack key={index} {...item}/>
            })
        }
    </div>
}
