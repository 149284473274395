import React from 'react'
import Slider from 'react-slick'
import { makeStyles, IconButton } from '@material-ui/core'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.css'
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles(theme => ({
    IconButton: {
        padding: '0px',
        [theme.breakpoints.up("sm")]: {
            padding: '12px'
        }
    }
}))

function NextArrow(props) {
    const classes = useStyles()
    const { onClick } = props;
    return (
        <IconButton onClick={onClick} className={classes.IconButton} >
            <NextIcon color='primary' />
        </IconButton>
    );
}

function PrevArrow(props) {
    const classes = useStyles()
    const { onClick } = props;
    return (
        <IconButton onClick={onClick} className={classes.IconButton} >
            <PrevIcon color='primary' />
        </IconButton>
    );
}

export default function Carousel({ children, dataList = [], variableWidth = false, otherSettings = {}, slidesInMd = 3, slidesInSm = 2, slidesInXs = 1, showArrow = true, showDots = false }) {
    const settings = {
        dots: showDots,
        infinite: dataList.length > slidesInMd,
        autoplay: true,
        swipeToSlide: true,
        arrows: showArrow,
        slidesToScroll: 1,
        variableWidth: variableWidth,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToShow: slidesInMd,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: slidesInSm,
                    infinite: dataList.length > slidesInSm,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: slidesInXs,
                    infinite: dataList.length > slidesInXs,
                    variableWidth: false,
                },
            },

        ],
        ...otherSettings
    }
    return <div>
        <Slider {...settings}>
            {children}
        </Slider>
    </div>
}