import React from 'react'
import {
    Dialog, DialogTitle, DialogContent, makeStyles, Grid, IconButton, Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
    root: {
        // zIndex: `99999 !important`
    },
    paperProps: {
        borderRadius: '8px',
        width: '90%',
        maxWidth: '600px'
    },
    titlePadding: {
        padding: '0.9rem',
        [theme.breakpoints.up('sm')]: {
            padding: '0.9rem 1.56rem',
        }
    },
    contentPadding: {
        padding: '0.9rem',
        paddingTop: '0px',
        [theme.breakpoints.up('sm')]: {
            padding: '0.9rem 1.56rem',
        }
    }
}))

function DialogComponent({ open, onClose, paperStyles = {}, showCloseIcon = true, showTitle = true, dialogTitle, scroll = 'body', ...props }) {
    const classes = useStyles()
    return (
        <Dialog open={open} onClose={onClose} scroll={scroll} classes={{
            root: classes.root
        }} PaperProps={{ className: classes.paperProps, style: paperStyles }}>
            {
                showTitle &&
                <DialogTitle className={classes.titlePadding}>
                    <Grid container justify='space-between' alignItems='center'>
                        <Grid item>
                            <Typography variant='h5'>{dialogTitle}</Typography>
                        </Grid>
                        {
                            showCloseIcon &&
                            <Grid item>
                                <IconButton style={{ padding: '0px' }} onClick={onClose}>
                                    <CloseIcon style={{ fontSize: '1.3rem' }} />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>

                </DialogTitle>
            }
            <DialogContent className={classes.contentPadding}>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}

export default DialogComponent