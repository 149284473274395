import React from 'react'
import { Grid, Hidden, Typography, makeStyles } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import SectionWrapper from '../General/SectionWrapper'
import PricingCard from './PricingCard'
import clsx from 'clsx'
import DialogComponent from '../General/DialogComponent'
import Form from '../Contact/Form'
import { dialogFormId, dialogFormDivId } from '../../utitlities/Configurations'
function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        borderRadius: "6px",
        padding: '20px'
    },
    activeContainer: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        borderRadius: "6px",
        padding: '20px',
        "& #link": {
            color: "white"
        },
    }
}))

export default function PricingPacks({ id = 'pricing-packs', styles = {}, packs = [], showTitle = true, titleAlign = 'center', title = 'Check our Plans', secondaryTitles = ['our'], smallTitle = 'Plans', subHeading = '' }) {
    const classes = useStyles()
    const [selectedPack, setSelectedPack] = React.useState(packs.length !== 0 ? packs[0] : {})
    const [open, setOpen] = React.useState(false)
    let ctaProps = {
        label: 'Talk to Sales',
        buttonProps: {
            id: 'talk-to-sales',
            onClick: () => {
                setOpen(true)
            }
        }
    }
    return <SectionWrapper id={id} styles={styles}>
        <Grid container spacing={4}>
            {
                showTitle &&
                <Grid item xs={12}>
                    <div style={{ paddingBottom: '1rem' }}>
                        <TitleComponent
                            title={title}
                            secondaryTitles={secondaryTitles}
                            titleAlign={titleAlign}
                            subHeading={subHeading}
                            smallTitle={smallTitle}
                        />
                    </div>
                </Grid>
            }
            <Grid item xs={12}>
                <Hidden smDown implementation='css'>
                    <Grid container justifyContent='center' spacing={3}>
                        {
                            packs.map(item => {
                                // console.log('navas')
                                // console.log(item)
                                return <Grid item xs={4} key={item.id}>
                                    <PricingCard
                                        data={item}
                                        ctaProps={ctaProps}
                                    />
                                </Grid>
                            })
                        }
                    </Grid>
                </Hidden>
                <Hidden mdUp implementation='css'>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={12} sm={5}>
                            <Grid container>
                                {
                                    packs.map(item => {
                                        return <Grid item xs={12}>
                                            <div onClick={() => setSelectedPack(item)} className={clsx({ [classes.container]: selectedPack.packName !== item.packName, [classes.activeContainer]: selectedPack.packName === item.packName })}>
                                                <Typography id='link' varaint='body1'>{Capitalize(item.packName)}</Typography>
                                            </div>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={7} >
                            <PricingCard
                                data={selectedPack}
                                ctaProps={ctaProps}
                            />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </Grid>
        <DialogComponent open={open} onClose={() => setOpen(false)} dialogTitle='Talk to Sales'>
            <Form
                formId={dialogFormId}
                formDivId={dialogFormDivId}
                showFullWidth={true}
            />
        </DialogComponent>
    </SectionWrapper>
}
